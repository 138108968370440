/* eslint-disable  @typescript-eslint/no-explicit-any */
import moment from "moment";
import { Lookup } from "@/model/Lookup";
import { mapData } from "@/utilities/DataMapping";

module Utils {

    export const sleep = (milliseconds: number): Promise<any> => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    //
    // -- strings
    //

    export function isEmptyOrWhitespace(text: string) {
        if (!text) return true;
        // see if any non-whitespace
        return !(/\S/.test(text));
    }
    
    //
    // -- GUIDs
    //

    export const emptyGuidValue = "00000000-0000-0000-0000-000000000000";

    // a GUID that is sortable in SQL server in date order
    export function newGuid(): string {
        function s4(): string {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }
        const dateHex = `000000000000${new Date().getTime().toString(16)}`;
        return `${s4()}${s4()}-${s4()}-4${s4().substr(1, 3)}-${s4()}-${dateHex.substr(dateHex.length - 12)}`;
    }

    export function isEmptyId(id: any): boolean {
        if (!id) return true;
        if (typeof id === "string") {
            return id === emptyGuidValue;
        }
        else if (typeof id === "number") {
            return id === 0;
        }
        return false;
    }

    export function isGuid(text: string): boolean {
        if (!text) return false;
        const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        return pattern.test(text);
    }

    //
    // -- enums
    //

    // https://stackoverflow.com/questions/50158272/what-is-the-type-of-an-enum-in-typescript
    type Enum<E> = Record<keyof E, number | string> & { [k: number]: string };

    export function camelCaseAddSpaces(text: string): string {
        if (!text) return "";
        const result = text.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    //
    // -- dates
    //

    export const emptyDateValue = -62135596800000;

    export function whenText(d: any): string {
        const dte = moment(d);
        if (!dte.isValid() || dte.year() < 1753) { return "- - -"; }
        const daysDiff = moment().startOf("day").diff(moment(d).startOf("day"), "days");
        if (daysDiff < 0) { return "in the future"; }
        if (daysDiff === 1) { return "yesterday"; }
        if (daysDiff > 7) { return moment(d).format("DD MMM YYYY"); }
        if (daysDiff > 1) { return `${daysDiff} days ago`; }
        const hoursDiff = moment().diff(dte, "hours");
        if (hoursDiff === 1) { return "an hour ago"; }
        if (hoursDiff > 1) { return `${hoursDiff} hours ago`; }
        const minsDiff = moment().diff(dte, "minutes");
        if (minsDiff === 1) { return "a minute ago"; }
        if (minsDiff > 1) { return `${minsDiff} mins ago`; }
        return "just now";
    }

    export function monthYearText(d: any): string {
        const m = moment(d);
        if (!m.isValid() || m.year() < 1753) {
            return "- - -";
        }
        return moment(d).format("MMMM YYYY");
    }

    export function dateText(d: any): string {
        const m = moment(d);
        if (!m.isValid() || m.year() < 1753) {
            return "- - -";
        }
        return moment(d).format("DD MMM YYYY");
    }

    export function dateTimeText(d: any): string {
        const m = moment(d);
        if (!m.isValid() || m.year() < 1753) {
            return "- - -";
        }
        // http://momentjs.com/docs/#/displaying/
        return moment(d).format("DD MMM YYYY HH:mm");
    }

    export function isDate(d: any) {
        if (!d) return false;
        return !!d.getMonth;
    }

    export function hasDateValue(d: any) {
        return isDate(d) && (+(d)) > -6847804800000; // 1753 = min SQL date
    }

    //
    // -- stuff...
    //

    export function debounce<F extends (...params: any[]) => void>(func: F, wait: number, immediate = false) {
        let timeout: number | undefined;
        return function(this: any, ...args: any[]) {
            const later = () => {
                timeout = undefined;
                if (!immediate) func.apply(this, args);
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(this, args);
        } as F;
    }

    export function clone(source: any, target: any) {
        Object.keys(source).forEach((key) => {
            target[key] = source[key];
        });
    }

    export function resetObject(obj: any) {
        for (const i in obj) {
            if (obj.hasOwnProperty(i)) {
                const type = typeof obj[i];
                //if (obj[i] instanceof Guid) {
                //    obj[i] = Guid.createEmpty();
                //}
                if (Object.prototype.toString.call(obj[i]) === "[object Date]") {
                    obj[i] = null;
                }
                if (type === "object") {
                    // recursive call
                    resetObject(obj[i]);
                }
                else if (type === "number" || type === "bigint") {
                    obj[i] = 0;
                }
                else if (type === "string") {
                    obj[i] = "";
                }
                else if (type === "boolean") {
                    obj[i] = false;
                }
                else if (Array.isArray(obj[i])) {
                    obj[i] = [];
                }
            }
        }
    }

    export function toMoney(num: number): string {
        const decimalSeparator = ".",
            thousandsSeparator = ",",
            currency = "£";

        let output = currency;

        let fixed = num.toFixed(2);

        fixed = (fixed.replace(/(\d)(?=(\d{3})+\.)/g, '$1' + thousandsSeparator));

        const thousands = fixed.slice(0, -3);
        const decimals = fixed.substr(-2, 2);
        output += (thousands + decimalSeparator + decimals);

        output = output.replace("£-", "-£");

        return output;
    }
    
    //
    // -- arrays (lookups)
    //

    // Lookups may contain archived items and possibly a default (none) item (or not)
    // For drop-down lists, we want a specific default item and to remove any archived items
    export function selectOptions(allItems: Array<Lookup>, defaultText: string|null): Array<Lookup> {
        if(defaultText == null) defaultText = "Please choose...";
        const defaultItem: Lookup = mapData({ id: 0, description: defaultText }, Lookup.mapping);
        if(!allItems) return [ defaultItem ];
        return [defaultItem, ...allItems.filter(lu => lu.id > 0)];
    }

    export function downloadBlob(document: Document, blob: Blob, overrideFilename?: string) {

        if(!(blob instanceof Blob)) {
            alert("Download failed - Unexpected data from server");
            return;
        }
        const maxSizeForBase64 = 1024 * 100; // not sure if this is really needed?
        const anchor = document.createElement("a");
        const windowUrl = window.URL || window.webkitURL;
        if (blob.size > maxSizeForBase64 && typeof windowUrl.createObjectURL === "function") {
            const fileUrl = windowUrl.createObjectURL(blob);
            anchor.download = overrideFilename ?? ""; 
            anchor.href = fileUrl;
            anchor.click();
            windowUrl.revokeObjectURL(fileUrl);
        }
        else {
            //use base64 encoding when less than set limit or file API is not available
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = () => {
                anchor.download = overrideFilename ?? "";
                if(typeof reader.result !== "string") {
                    alert("Download failed");
                    return;
                }
                anchor.href = reader.result;
                anchor.click();
            }
        }
    }
}

export default Utils;