// Same as TriState in Visual Basic
export enum TriState {
    UseDefault = -2, // 0xFFFFFFFE
    True = -1, // 0xFFFFFFFF
    False = 0
}

export enum UserRole {
    None = 0,
    Admin = 1,
    Basic = 2
}

export enum UserStatus {
    None = 0,
    AwaitingActivation = 1,
    Active = 2,
    Deleted = 3
}

export enum ChartFilterOptions {
    None = 0,
    LastYear = 1,
    CurrentYear = 2,
    SpecificDates = 3
}