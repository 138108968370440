import { IMappingTranslators, mapData } from "@/utilities/DataMapping";
import utils from "@/utilities/Utils";

export class Lookup  {

    constructor(data?: any) {
        if (data) this.update(data)
    }

    update(data: any) {
        mapData(data, { root: () => this });
    }

    id = 0; 
    guid: string = utils.newGuid();
    description = "";

    static mapping: IMappingTranslators = {
        root: () => new Lookup()
    }
    
}