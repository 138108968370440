import Vue from "vue";
import { Validation } from "vuelidate";
import utils from "@/utilities/Utils";
import { Lookup } from "@/model/Lookup";

//
// -- dates
//

Vue.filter("dateText", (value: Date): string => {
    if (!value) return "- - -";
    return utils.dateText(value);
});

Vue.filter("whenText", (value: Date): string => {
    if (!value) return "- - -";
    return utils.whenText(value);
});

//
// -- numbers
//

Vue.filter("currency", (value: number): string => {
    //return !isNaN(value) && value.toLocaleString(undefined, {maximumFractionDigits:2}) || "0.00";
    return !isNaN(value) ? utils.toMoney(value) : "0.00";
});

//
// -- lookups
//

Vue.filter("lookup", (id: number | string, lookupList: Array<Lookup>, defaultText: string = "", failedText: string = ""): string => {
    if (!failedText) failedText = `(id=${id}) opts=${(lookupList ? lookupList.length : (typeof lookupList))}`;
    if (id === 0 || id === "" || id === utils.emptyGuidValue) return defaultText;
    if (!Array.isArray(lookupList)) return "(options not an array)";
    if (lookupList.length === 0) return "(empty options array)";
    const item = lookupList.filter(l => l.id === id)[0];
    return item ? item.description : failedText;
});

//
// -- validation
//

//Connect vuelidate to bootstrap-vue state
Vue.filter("validationState", (validation: Validation): boolean | null => {
    if (validation == null) return null;
    if (validation.$error) return false;
    return null;
});

Vue.filter("validationStateText", (validation: Validation): string => {
    if (validation == null) return "null";
    if (validation.$invalid && !validation.$error) return "inv no error";
    if (validation.$invalid) return "invalid";
    if (!validation.$error) return "no error";
    return "error";
});
