import utils from "@/utilities/Utils";
import { UserRole, UserStatus } from "./Enums";
import { mapData, IMappingTranslators } from "@/utilities/DataMapping";

export class UserAgreementCustomer {

    constructor(data?: any) {
        if (data) this.update(data)
    }

    dropAccount: number = 0;
    customerName: string = "";
    isActive: boolean = false;

    update(data: any) {
        mapData(data, { root: () => this });
    }

}

export class UserAgreement {

    agreementNumber: number = 0;
    agreementName: string = "";
    canViewAllAccounts: boolean = false;
    cprpdTarget: number = 0;
    dropAccounts: Array<number> = [];

    static mapping: IMappingTranslators = {
        root: () => new UserAgreement()
    }

}

export class User {

    id: number = 0;
    guid: string = utils.emptyGuidValue;
    status: UserStatus = UserStatus.None;
    role: UserRole = UserRole.None;
    email: string = "";
    forename: string = "";
    surname: string = "";
    lastLoggedIn: Date = new Date(utils.emptyDateValue);
    prevLastLoggedIn: Date = new Date(utils.emptyDateValue);
    agreements: Array<UserAgreement> = [];
    jwtBearerToken: string = "";

    get isNew() {
        return utils.isEmptyId(this.id);
    }

    get fullname() {
        return this.forename + " " + this.surname;
    }

    static mapping: IMappingTranslators = {
        root: () => new User(),
        agreements: () => new UserAgreement()
    }

}