import Vue from "vue";
import VueRouter, { RouteConfig, NavigationGuard, Route, RawLocation } from "vue-router";
import store from "@/store/store";
import dirtyness from "@/utilities/Dirtyness";

Vue.use(VueRouter as any); // eslint-disable-line @typescript-eslint/no-explicit-any

let intendedUrl: string | null = null;

// call this in the *component* code (not here in the router setup)
export function beforeRouteLeave(to: Route, from: Route,  next: (to?: string | void | false) => void) {
    if(dirtyness.isDirty && !confirm("Changes may not be saved.  Click cancel to stay on this page.")) {
        return next(false);
    }
    else {
        dirtyness.reset();
        return next();
    }
}

const beforeEnterGuard: NavigationGuard = async (to: Route, from: Route, next: (to?: string | void) => void) => {

    //console.log(`### nav guard... signed in=${store.getters.isSignedIn} intendedUrl=${intendedUrl}`);

    if (store.getters.isSignedIn) {
        if (intendedUrl) {
            const url = intendedUrl;
            intendedUrl = null;
            console.log("### nav guard - now logged in - redirect");
            return next(url); // goto stored url
        } else {
            console.log("### nav guard - carry on...");
            return next(); // all is fine
        }
    }
    
    try {
        // get user from session storage
        await store.dispatch("loadSignedInUser");
    } catch (e) {
        console.log("@@@ loadSignedInUser failed ");
        console.error(e);
    } 
    
    if (store.getters.isSignedIn) {
        console.log("### nav guard - got login ok");
        next();
    } else {
        intendedUrl = to.path; // store entry url before redirect
        console.log("### nav guard - redirect to signin");
        next("/SignIn");
    }
};

//
// -- set up the routes
//

const routes: Array<RouteConfig> = [];

const signIn = () => import("../views/SignIn.vue");
const dashboard = () => import("../views/Dashboard.vue");
const categories = () => import("../views/Categories.vue");
const customers = () => import("../views/Customers.vue");
const comparisonReports = () => import("../views/ComparisonReport.vue");
const downloads = () => import("../views/Downloads.vue");
const residents = () => import("../views/Residents.vue");

routes.push({ path: "/", name: "SignIn", meta: { layout: "blank" }, component: signIn });
routes.push({ path: "/SignIn", name: "SignIn2", meta: { layout: "blank" }, component: signIn });
routes.push({ path: "/Activate/:key", name: "Activate", meta: { layout: "blank" }, component: () => import("../views/ResetPassword.vue") });
routes.push({ path: "/Reset/:key", name: "Reset", meta: { layout: "blank" }, component: () => import("../views/ResetPassword.vue") });
routes.push({ path: "/Error", name: "Error500", meta: { layout: "signed-out" }, component: () => import("../views/Error500.vue") });

routes.push({ path: "/Dashboard", name: "Dashboard", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: dashboard });

routes.push({ path: "/Categories", name: "Categories", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: categories })
routes.push({ path: "/Category/:categoryID", name: "Category", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: categories });
routes.push({ path: "/Supplier/:supplierID", name: "Supplier", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: categories });  

routes.push({ path: "/Spend", name: "Spend", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: customers });
routes.push({ path: "/Customers", name: "Customers", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: customers });
routes.push({ path: "/Customer/:dropAccount", name: "Customer", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: customers });
routes.push({ path: "/ComparisonReport", name: "ComparisonReport", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: comparisonReports });
routes.push({ path: "/Downloads", name: "Downloads", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: downloads });
routes.push({ path: "/Residents", name: "Residents", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: residents });

// This needs to be the **last** route  
routes.push({ path: "*", meta: { layout: "signed-out", }, component: () => import("../views/Error404.vue") });

//
// -- create the router
//

const router = new VueRouter({
    mode: "history",
    linkActiveClass: "active",
    linkExactActiveClass: "active",
    base: process.env.BASE_URL,
    routes
});

export function goToIntendedUrl() {
    if (!intendedUrl) router.replace("/Dashboard/"); // default in case not defined (?)
    router.replace(intendedUrl as RawLocation);
}

export default router;