
    import Vue from "vue";
    import Component from "vue-class-component";
    import { required, email } from "vuelidate/lib/validators";
    import { Validations } from "vuelidate-property-decorators";
    import * as Toastr from "toastr";
    import SlideUpDown from "vue-slide-up-down";
    import authentication from "@/utilities/Authentication";
    import apiClient from "@/utilities/ApiClient";
    import ApiButton from "../components/ApiButton.vue";
    import { User } from "../model/User";
    import { mapData } from "@/utilities/DataMapping";
   
    // We could put these interfaces (or classes) in their own files - but they're small 
    // and so far they're only used here.

    interface ISignInRequest {
        email: string;
        password: string;
    }

    export interface ISignInResponse {
        isSuccess: boolean;
        message: string;
        user: User;
    }

    interface IForgotRequest {
        email: string;
    }

    interface IForgotResponse {
        isSuccess: boolean;
        message: string;
    }

    // https://vuejs.org/v2/guide/typescript.html

    @Component({
        components: { SlideUpDown, ApiButton }
    })     
    export default class SignInForm extends Vue {

        // Lifecycle hook
        mounted() {
            this.loadFromStorage()
        }

        signInRequest: ISignInRequest = { email: "", password: "" };

        forgotRequest: IForgotRequest = { email: "" };

        isForgotMode = false;

        messageText = "";

        get isMessage(): boolean {
            return !!this.messageText;
        }

        // get properties are 'computeds'
        get isAuthenticated(): boolean {
            return this.$store.state.signedInUser !== null;
        }

        get userName(): string {
            const user = this.$store.state.signedInUser as User;
            if (user === null) return "not logged in";
            return `${user.forename} ${user.surname}`;
        }

        toggleMode() {
            this.$v.$reset(); // reset validation
            this.messageText = "";
            if (!this.isForgotMode && this.signInRequest.email) {
                // copy the email over to save user typing it out again
                this.forgotRequest.email = this.signInRequest.email;
            }
            this.isForgotMode = !this.isForgotMode;
        }

        async signIn(event: Event) {
            // TODO - if this triggered from enter key, we could do with finding anad passing the button...
            
            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();

            if (this.$v.signInRequest.$invalid) {
                Toastr.error("Please fix the highlighted errors", "Can't sign-in");
                return;
            }

            const response: ISignInResponse = await apiClient.post("/Api/Authentication/signin", this.signInRequest, event);

            await authentication.signIn(mapData(response.user, User.mapping));

            if (this.isAuthenticated) {
                this.saveToStorage(response.user);
                console.log("...SignInForm component - emitting is-authenticated");
                this.$emit("is-authenticated");
            }
            else {
                this.messageText = response.message;
            }
        }

        async submitForgot() {
            this.$v.$touch();
            if (this.$v.forgotRequest.$invalid) {
                Toastr.error("Please fix the highlighted error");
                return;
            }
            const response: IForgotResponse = await apiClient.post("/Api/Authentication/forgot", this.forgotRequest);
            this.messageText = response.message;
        }

        private loadFromStorage() {
            if (typeof (Storage) === "undefined") return;
            const userJson = localStorage.getItem("user");
            if (!userJson) return;
            try {
                const user = JSON.parse(userJson);
                this.signInRequest.email = (user && user.email) ? user.email : "";
                this.forgotRequest.email = this.signInRequest.email;
            } catch (e) {
                this.signInRequest.email = "";
            }
        }

        private saveToStorage(user: User) {
            if (typeof (Storage) === "undefined") return;
            localStorage.setItem("user", JSON.stringify(user));
        }

        @Validations()
        validations() {
            return {
                signInRequest: {
                    email: { required, email },
                    password: { required }
                },
                forgotRequest: {
                    email: { required, email }
                }
            };
        }
    }

