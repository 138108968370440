
    import Vue from "vue";
    import Component from "vue-class-component";
    import { required } from "vuelidate/lib/validators";
    import { Validations } from "vuelidate-property-decorators";
    import * as toastr from "toastr";
    import ApiButton from "@/components/ApiButton.vue";
    import buttonToggler from "@/utilities/ButtonToggler";
    import utils from "@/utilities/Utils";
    import apiClient from "@/utilities/ApiClient";
    import { PasswordChangeRequest } from '../model/PasswordChangeRequest';

    interface IPasswordCheckResult {
        isOkay: boolean;
        message: string;
    }

    @Component({
        components: { ApiButton }
    })
    export default class ChangePasswordDialogue extends Vue {

        //
        // -- properties
        //

        passwordChange = new PasswordChangeRequest();

        invalidPasswordMessage: string = "";

        show() {
            utils.resetObject(this.passwordChange);
            this.$v.$reset();
            this.$bvModal.show("changePasswordDialogue");
        }

        hide() {
            this.$bvModal.hide("changePasswordDialogue");
            utils.resetObject(this.passwordChange);
        }

        async save(event: Event) {
            // validation goes off to server here - so show button spinner
            // (see company for non-async approach)
            const button = buttonToggler.getButton(event);
            buttonToggler.disableButton(button);
            if (!await this.isValid()) {
                toastr.info("Please fix the highlighted errors");
                buttonToggler.enableButton(button);
                return;
            }
            buttonToggler.enableButton(button); // if we don't re-enable now, css is wrong when we try and re-enable later

            const response = await apiClient.post("/api/authentication/changePassword", this.passwordChange, event); 

            if (response.message === "ok") {
                toastr.success("Changed Password");
                this.hide();
            }
            else {
                //TODO - figure this out 
                alert(response.error);
            }            
        }

        //
        // -- validation
        //

        private async validatePassword(password: string): Promise<boolean> {
            const response: IPasswordCheckResult = await apiClient.get("/api/authentication/validatePassword?password=" + encodeURIComponent(password));
            this.invalidPasswordMessage = response.message;
            return response.isOkay;
        }

        private async checkExisting(password: string): Promise<boolean> {
            const response: IPasswordCheckResult = await apiClient.get("/api/authentication/verifyPassword?password=" + encodeURIComponent(password));
            this.invalidPasswordMessage = response.message;
            return response.isOkay;
        }

        @Validations()
        validations() {
            const validations = {
                passwordChange: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            validations.passwordChange.existingPassword = { checkExisting: this.checkExisting };
            validations.passwordChange.newPassword = { required, validatePassword: this.validatePassword };
            validations.passwordChange.repeatPassword = {
                passwordsMatch: (value: string, model: PasswordChangeRequest) => {
                    return model.newPassword === model.repeatPassword;
                }
            };
            return validations;
        }

        // from https://github.com/vuelidate/vuelidate/issues/179
        private async isValid(): Promise<boolean> {
            this.$v.$reset();
            this.$v.$touch();
            await this.waitForValidation();
            return Promise.resolve(!this.$v.$error);
        }

        // from https://github.com/vuelidate/vuelidate/issues/179
        private waitForValidation() {
            return new Promise(resolve => {
                if (this.$v.$error || !this.$v.$pending) {
                    return resolve("success");
                }
                const poll = setInterval(() => {
                    if (!this.$v.$pending) {
                        clearInterval(poll)
                        resolve("success")
                    }
                }, 200);
            })
        }
    }
