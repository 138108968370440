

    // Stuff with $listeners:
    // https://vuejs.org/v2/guide/components-custom-events.html

    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";

    @Component({})
    export default class ApiButton extends Vue {

        @Prop({ default: "fa-chevron-right" })
        private icon!: string;

        @Prop({ default: "primary" })
        private variant!: string;

        get variantClass(): string {
            if (this.variant === "primary") return "btn btn-primary";
            if (this.variant === "secondary") return "btn btn-secondary";
            if (this.variant === "success") return "btn btn-success";
            if (this.variant === "danger") return "btn btn-danger";
            if (this.variant === "warning") return "btn btn-warning";
            if (this.variant === "info") return "btn btn-info";
            if (this.variant === "light") return "btn btn-light";
            if (this.variant === "dark") return "btn btn-dark";
            if (this.variant === "outline-primary") return "btn btn-outline-primary";
            if (this.variant === "outline-secondary") return "btn btn-outline-secondary";
            if (this.variant === "outline-success") return "btn btn-outline-success";
            if (this.variant === "outline-danger") return "btn btn-outline-danger";
            if (this.variant === "outline-warning") return "btn btn-outline-warning";
            if (this.variant === "outline-info") return "btn btn-outline-info";
            if (this.variant === "outline-light") return "btn btn-outline-light";
            if (this.variant === "outline-dark") return "btn btn-outline-dark";
            return "btn btn-primary";
        }

        isDisabled: boolean = false;

        // eslint-disable-line @typescript-eslint/no-explicit-any
        get listeners(): any {
            // we could add in our own custom listeneres here
            return { ...this.$listeners };
        }
    }

