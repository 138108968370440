/* eslint-disable  @typescript-eslint/no-explicit-any */

module ButtonToggler {

    function removeCssClass(elem: HTMLElement, className: string) {
        const regex = new RegExp("(?:^|\\s)" + className + "(?!\\S)", "g");
        elem.className = elem.className.replace(regex, "");
    }

    function hasCssClass(elem: HTMLElement, className: string): boolean {
        const regex = new RegExp("(?:^|\\s)" + className + "(?!\\S)", "g");
        const m = elem.className.match(regex);
        return !!m && m.length !== 0;
    }

    function addCssClass(elem: HTMLElement, className: string) {
        elem.className = elem.className + " " + className;
    }

    export function getButton(eventOrButton: any): HTMLInputElement | undefined  {
        if (!eventOrButton) return undefined;

        if (!!eventOrButton &&
            !!eventOrButton.tagName &&
            eventOrButton.tagName.toLowerCase() !== "button") return eventOrButton as HTMLInputElement;

        // event.srcElement is deprecated
        // event.currentTarget is whatever the event listener is attached to
        // event.target is the actual thing that the user clicked on

        if (!!eventOrButton.target &&
            !!eventOrButton.target.tagName &&
            eventOrButton.target.tagName.toLowerCase() === "button") return eventOrButton.target as HTMLInputElement;

        if (!!eventOrButton.srcElement &&
            !!eventOrButton.srcElement.tagName &&
            eventOrButton.srcElement.tagName.toLowerCase() === "button") return eventOrButton.target as HTMLInputElement;

        return undefined;
    }

    // prevent buttons being pressed twice
    export function disableButton(button?: HTMLInputElement) {
        if (!button) return;

        button.disabled = true;

        // if button has spinner, show it and hide its icon
        for (let i = 0; i < button.childNodes.length; i++) {
            const node = button.childNodes[i];
            const elementWithCss = (node as any).className ? (node as HTMLElement) : undefined;
            if (elementWithCss && hasCssClass(elementWithCss, "fas")) {
                elementWithCss.setAttribute("data-save-class-name", elementWithCss.className);
                // TODO gat other classes (like ml-2) from the element and preserve them?
                elementWithCss.className = "ml-2 fas fa-spinner fa-pulse";
            }
        }

        if (!hasCssClass(button, "disabled")) {
            addCssClass(button, "disabled");
        }
        return;
    }

    export function enableButton(button?: HTMLInputElement) {
        if (!button) return;

        button.disabled = false;

        // if button has spinner, hide it and re-show its icon
        for (let i = 0; i < button.childNodes.length; i++) {
            const node = button.childNodes[i];
            const elementWithCss = (node as any).className ? (node as HTMLElement) : undefined;
            if (elementWithCss && elementWithCss.hasAttribute("data-save-class-name")) {
                const oldClassName = elementWithCss.getAttribute("data-save-class-name");
                if (oldClassName) {
                    elementWithCss.removeAttribute("data-save-class-name");
                    elementWithCss.className = oldClassName;
                }
            }
        }
        removeCssClass(button, "disabled");
    }
} 

export default ButtonToggler;