
    import Vue from "vue";
    import Component from "vue-class-component";
    import eventBus from "./utilities/EventBus";
    import apiClient, { ISendRequestParameters } from "./utilities/ApiClient";
    import * as toastr from "toastr";

    const defaultLayout = "signed-out";

    import SignInDialogue from "./components/SignInDialogue.vue";
    import authentication from "@/utilities/Authentication";

    @Component({
        components: { SignInDialogue }
    })
    export default class App extends Vue {

        private pendingApiParameters: ISendRequestParameters | null = null;

        mounted() {

            // Prevent any XSS shenanigans by default
            toastr.options.escapeHtml = true;

            eventBus.$on("http-401", (parameters: ISendRequestParameters) => {
                authentication.signOut();
                this.$router.push("/");
            });

            eventBus.$on("http-error", (response: Response) => {
                const contentType = response.headers.get("Content-Type");
                const status = response.status;
                if (status === 500) {
                    response.text().then(bodyText => {
                        // if we're running in production, we'll probably not get a response body - but handy to show in dev mode
                        if (bodyText) {
                            this.showErrorMessageWithContent("Server Error", "Status = " + response.status, bodyText);
                        }
                        else {
                            this.showErrorMessage("Server Error", "Status = " + response.status);
                        }                       
                    });
                }
                else {
                    this.showErrorMessage("HTTP Error", "Status = " + response.status);
                }
            });

            eventBus.$on("fetch-exception", (error: Error) => {
                // TODO what if error is null or message is null
                this.showErrorMessage("API Error", error.message);
            });
        }

        onAuthenticated() {
            console.log("...App component - onAuthenticated");
            if (this.pendingApiParameters == null) {
                console.log("...App component - onAuthenticated - pendingApiParameters is **NULL**!");
                // what should we do if we ever get here?!!
                return;
            }            
            console.log("...App component - resend last API request");
            apiClient.sendRequest(this.pendingApiParameters);
            this.pendingApiParameters = null;
        }

        showErrorMessageWithContent(title: string, message: string, content: string) {
            const ce = this.$createElement;

            const messageVNode = ce('div', {}, [
                ce('div', { class: ['errorMessageContainer'] }, [
                    ce('div', { class: ['fas fa-bomb errorMessageIcon'] }, []),
                    ce('div', { class: ['errorMessageText'] }, [message])
                ]),
                ce('div', { class: ['errorMessageContent'] }, [content])
            ]);

            this.$bvModal.msgBoxOk([messageVNode], {
                title: title,
                size: "xl",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "dismiss",
                headerClass: "p-2 border-bottom-0",
                footerClass: "p-2 border-top-0",
                centered: true
            });
            // .then(value => { })
            // .catch(err => { });
        }

        showErrorMessage(title: string, message: string) {
            const h = this.$createElement;

            const messageVNode = h('div', { class: ['errorMessageContainer'] }, [
                h('div', { class: ['fas fa-bomb errorMessageIcon'] }, []),
                h('pre', { class: ['errorMessageText'] }, [ message ])
            ]);

            this.$bvModal.msgBoxOk([messageVNode], {
                title: title,
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "dismiss",
                headerClass: "p-2 border-bottom-0",
                footerClass: "p-2 border-top-0",
                centered: true
            });
               // .then(value => { })
               // .catch(err => { });
        }

        // computed property used to select layout - see also main.ts
        get layout() {
            if (this.$route.meta == undefined || !this.$route.meta.layout) return null;
            return (this.$route.meta.layout || defaultLayout) + "-layout";
        }
    }
