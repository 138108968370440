
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Watch } from "vue-property-decorator";
    import apiClient from "@/utilities/ApiClient";
    import authentication from "@/utilities/Authentication";
    import { mapData } from "@/utilities/DataMapping";
    import store from "@/store/store";
    import { UserAgreement, UserAgreementCustomer } from "@/model/User";
    import { Lookup } from "@/model/Lookup";
    import ChangePasswordDialogue from "../components/ChangePasswordDialogue.vue";
    import Utils from "@/utilities/Utils";

    @Component({
        components: { ChangePasswordDialogue }
    })
    export default class SignedInLayout extends Vue {

        //
        // -- lifecycle hooks
        //

        async mounted() {
            document.body.style.backgroundColor = "#fff";

            store.dispatch("setLayoutInitDone", false);

            await this.getAgreements();
            await this.getCustomers(this.agreementNumber);
            await this.getCategories();
            await this.getSuppliers();

            this.loaded = true

            store.dispatch("setLayoutInitDone", true);
        }

        //
        // -- properties
        //

        private loaded: boolean = false;
        private agreements: Array<UserAgreement> = [];
        private customers: Array<UserAgreementCustomer> = [];
        private categories: Array<Lookup> = [];
        private suppliers: Array<Lookup> = [];

        private agreementNumber: number = 0;
        private includeInactive: boolean = false;

        get signedInUserName(): string {
            return store.getters.signedInUserName;
        }

        get hasInactiveCustomers(): boolean {
            return this.customers.filter(c => !c.isActive).length > 0
        }

        get availableCustomers(): Array<UserAgreementCustomer> {
            return this.customers.filter(c => c.isActive || this.includeInactive);
        }

        //
        // --  watchers
        //

        @Watch("agreementNumber")
        async onAgreementNumberChanged(value: number) {
            if (!this.loaded) return;

            store.dispatch("setLayoutInitDone", false);    
                    
            store.dispatch("setStartDate", null);
            store.dispatch("setEndDate", null);
            store.dispatch("setSelectedFilter", null);
            store.dispatch("setIncludeInactive", false);

            this.includeInactive = false;

            await this.getSuppliers();
            await this.getCustomers(value);
            
            const agreements = this.agreements.filter(agr => agr.agreementNumber === value);
            const agreementName = agreements.length > 0 ? agreements[0].agreementName : "";
            const canViewAllAccounts = agreements.length > 0 ? agreements[0].canViewAllAccounts : false;
            const cprpdTarget = agreements.length > 0 ? agreements[0].cprpdTarget : 0;
            
            store.dispatch("setAgreementNumber", value);
            store.dispatch("setAgreementName", agreementName);
            store.dispatch("setCanViewAllAccounts", canViewAllAccounts);
            store.dispatch("setCprpdTarget", cprpdTarget)
            
            store.dispatch("setLayoutInitDone", true);
        }

        @Watch("includeInactive")
        async onIncludeInactiveChanged(value: boolean) {
            if (!this.loaded) return;
            await store.dispatch("setIncludeInactive", value);
        }

        @Watch("$store.state.cprpdTarget")
        private onTargetChanged(value: number) {
            const agr = this.agreements.filter(a => a.agreementNumber == this.agreementNumber);
            if (agr.length == 1) {
                agr[0].cprpdTarget = value;
            }
        }

        // 
        // -- methods
        //

        signOut() {
            // Not using async / await here so we'll just trust the sign out occurs in the background
            // and rely on error handler if anything screws up.
            authentication.signOut();

            this.$router.push("/");
        }

        changePassword() {
            const dialogue = this.$refs.changePassword as ChangePasswordDialogue & { show: () => void };
            dialogue.show();
        }

        //
        // -- private methods
        //

        private async getAgreements() {
            const serverData = await apiClient.get("api/agreement/agreements");
            this.agreements = mapData<Array<UserAgreement>>(serverData, UserAgreement.mapping);
            const hasAgr = !Utils.isEmptyId(this.$store.state.agreementNumber) && 
                            this.agreements.filter(agr => agr.agreementNumber === +this.$store.state.agreementNumber).length > 0;

            if (!hasAgr) {
                store.dispatch("setAgreementNumber", this.agreements[0].agreementNumber);
                store.dispatch("setAgreementName", this.agreements[0].agreementName);
                store.dispatch("setCanViewAllAccounts", this.agreements[0].canViewAllAccounts);
                store.dispatch("setCprpdTarget", this.agreements[0].cprpdTarget);
                this.agreementNumber = this.agreements[0].agreementNumber;
            }
            else {
                const agreement = this.agreements.filter(agr => agr.agreementNumber === +this.$store.state.agreementNumber)[0];
                this.agreementNumber = this.$store.state.agreementNumber;  
                store.dispatch("setAgreementName", agreement.agreementName);
                store.dispatch("setCanViewAllAccounts", agreement.canViewAllAccounts);
                store.dispatch("setCprpdTarget", agreement.cprpdTarget);              
            }
        }

        private async getCustomers(value: number) {
            const serverData = await apiClient.get(`api/agreement/customers?agreementNumber=${value}`);
            this.customers.length = 0;
            this.customers.push(...serverData.map((c: any) => new UserAgreementCustomer(c)));

            if (this.customers.length === 1) {
                store.dispatch("setDropAccount", this.customers[0].dropAccount);
                store.dispatch("setCustomerName", this.customers[0].customerName);
                store.dispatch("setCanChangeCustomer", false);
            }
            else {
                store.dispatch("setDropAccount", null);
                store.dispatch("setCustomerName", null);
                store.dispatch("setCanChangeCustomer", true);
            }
        }

        private async getCategories() {
            const serverData = await apiClient.get("api/agreement/categories");
            this.categories = mapData<Array<Lookup>>(serverData, Lookup.mapping);
        }

        private async getSuppliers() {
            const serverData = await apiClient.get(`api/agreement/suppliers?id=${this.agreementNumber}`);
            this.suppliers = mapData<Array<Lookup>>(serverData, Lookup.mapping)
        }

    }
