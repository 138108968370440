/* eslint-disable  @typescript-eslint/no-explicit-any */
import Vue from "vue";
import Vuex, { StoreOptions, MutationTree, ActionTree, ActionContext } from "vuex";
import createPersistedState from "vuex-persistedstate";
import utils from "@/utilities/Utils";
import { User } from "@/model/User";
import { mapData } from "@/utilities/DataMapping";
import { ChartFilterOptions } from "@/model/Enums";

Vue.use(Vuex);

//
// -- This allows us to keep globally available data
//    that we can bind to in components...
//

// Define what we're storing
export interface IStoreState {
    signedInUser: User | null;
    pendingApiCall: Promise<any> | null;
    agreementNumber: number;
    agreementName: string;
    canViewAllAccounts: boolean;
    cprpdTarget: number;
    dropAccount: number|null;
    customerName: string|null;
    canChangeCustomer: boolean;
    layoutInitDone: boolean;

    // chart filters
    selectedFilter: ChartFilterOptions|null;
    startDate: Date|null;
    endDate: Date|null;
    includeInactive: boolean;
}

//
// -- build an options object
//

const dataState = createPersistedState({
    // items kept in local storage
    paths: [
        "agreementNumber", 
        "agreementName", 
        "canViewAllAccounts",
        "cprpdTarget",
        "dropAccount", 
        "customerName", 
        "canChangeCustomer",

        // chart filters
        "selectedFilter",
        "startDate",
        "endDate",
        "includeInactive"
    ]
})

const options: StoreOptions<IStoreState> = {
    plugins: [dataState],
    state: () => ({
        // shared state
        signedInUser: null as User|null,
        pendingApiCall: null,
        agreementNumber: 0,
        agreementName: "",
        canViewAllAccounts: false,
        cprpdTarget: 0,
        dropAccount: null,
        customerName: null,
        canChangeCustomer: false,
        layoutInitDone: false,
        startDate: null,
        endDate: null,
        selectedFilter: null,
        includeInactive: false
    }),
    getters: {
        // computed state
        isSignedIn: (state: IStoreState) => state.signedInUser !== null && !utils.isEmptyId(state.signedInUser.id),
        signedInUserName: (state: IStoreState) => (state.signedInUser !== null && !utils.isEmptyId(state.signedInUser.id)) ? state.signedInUser.fullname : "(not signed in)"
    },
    mutations: {},  // added below
    actions: {},    // added below
    modules: {}     // guess we don't need this...
};

// couple of shortcuts (and tell subsequent typescript they're *not* undefined)
const mutations = options.mutations as MutationTree<IStoreState>;
const actions = options.actions as ActionTree<IStoreState, IStoreState>;

// used during signing back in
mutations.setPendingApiCall = (state: IStoreState, pendingApiCall: Promise<any>) => {
    state.pendingApiCall = pendingApiCall;
};
actions.setPendingApiCall = (context: ActionContext<IStoreState, IStoreState>, pendingApiCall: Promise<any>) => {
    console.log("STORE - action - setPendingApiCall");
    context.commit("setPendingApiCall", pendingApiCall);
};

// the current user - see @/utilities/Authentication module
mutations.setSignedInUser = (state: IStoreState, user: User) => {
    state.signedInUser = user;
};
actions.loadSignedInUser = (context: ActionContext<IStoreState, IStoreState>) => {
    // try and retrieve from session storage
    const userJson = sessionStorage.getItem("signedInUser");
    if(!userJson) return;
    const user =  mapData(JSON.parse(userJson as string), User.mapping);
    context.commit("setSignedInUser", user);
};
actions.setSignedInUser = (context: ActionContext<IStoreState, IStoreState>, user: User) => {
    sessionStorage.setItem("signedInUser", JSON.stringify(user));
    context.commit("setSignedInUser", user);
};

mutations.setAgreementNumber = (state: IStoreState, agreementNumber: number) => {
    state.agreementNumber = agreementNumber;
};
actions.setAgreementNumber = (context: ActionContext<IStoreState, IStoreState>,  agreementNumber: number) => {
    context.commit("setAgreementNumber", agreementNumber)
};

mutations.setAgreementName = (state: IStoreState, agreementName: string) => {
    state.agreementName = agreementName;
};
actions.setAgreementName = (context: ActionContext<IStoreState, IStoreState>,  agreementName: string) => {
    context.commit("setAgreementName", agreementName)
};

mutations.setCanViewAllAccounts = (state: IStoreState, canViewAllAccounts: boolean) => {
    state.canViewAllAccounts = canViewAllAccounts;
};
actions.setCanViewAllAccounts = (context: ActionContext<IStoreState, IStoreState>,  canViewAllAccounts: boolean) => {
    context.commit("setCanViewAllAccounts", canViewAllAccounts)
};

mutations.setCprpdTarget = (state: IStoreState, cprpdTarget: number) => {
    state.cprpdTarget = cprpdTarget;
};
actions.setCprpdTarget = (context: ActionContext<IStoreState, IStoreState>,  cprpdTarget: number) => {
    context.commit("setCprpdTarget", cprpdTarget)
};

mutations.setDropAccount = (state: IStoreState, dropAccount: number|null) => {
    state.dropAccount = dropAccount;
};
actions.setDropAccount = (context: ActionContext<IStoreState, IStoreState>,  dropAccount: number|null) => {
    context.commit("setDropAccount", dropAccount)
};

mutations.setCustomerName = (state: IStoreState, customerName: string|null) => {
    state.customerName = customerName;
};
actions.setCustomerName = (context: ActionContext<IStoreState, IStoreState>,  customerName: string|null) => {
    context.commit("setCustomerName", customerName)
};

mutations.setCanChangeCustomer = (state: IStoreState, canChangeCustomer: boolean) => {
    state.canChangeCustomer = canChangeCustomer;
};
actions.setCanChangeCustomer = (context: ActionContext<IStoreState, IStoreState>,  canChangeCustomer: boolean) => {
    context.commit("setCanChangeCustomer", canChangeCustomer)
};

mutations.setLayoutInitDone = (state: IStoreState, layoutInitDone: boolean) => {
    state.layoutInitDone = layoutInitDone;
};
actions.setLayoutInitDone = (context: ActionContext<IStoreState, IStoreState>,  layoutInitDone: boolean) => {
    context.commit("setLayoutInitDone", layoutInitDone)
};

mutations.setStartDate = (state: IStoreState, startDate: Date|null) => {
    state.startDate = startDate;
};
actions.setStartDate = (context: ActionContext<IStoreState, IStoreState>,  startDate: Date|null) => {
    context.commit("setStartDate", startDate)
};

mutations.setEndDate = (state: IStoreState, endDate: Date|null) => {
    state.endDate = endDate;
};
actions.setEndDate = (context: ActionContext<IStoreState, IStoreState>,  endDate: Date|null) => {
    context.commit("setEndDate", endDate)
};

mutations.setSelectedFilter = (state: IStoreState, selectedFilter: ChartFilterOptions|null) => {
    state.selectedFilter = selectedFilter;
};
actions.setSelectedFilter = (context: ActionContext<IStoreState, IStoreState>,  selectedFilter: ChartFilterOptions|null) => {
    context.commit("setSelectedFilter", selectedFilter)
};

mutations.setIncludeInactive = (state: IStoreState, includeInactive: boolean) => {
    state.includeInactive = includeInactive;
}
actions.setIncludeInactive = (context: ActionContext<IStoreState, IStoreState>, includeInactive: boolean) => {
    context.commit("setIncludeInactive", includeInactive);
}

//
// -- the main deal - export the Store
//

export default new Vuex.Store<IStoreState>(options);